import React from 'react';
import styled from 'styled-components';
import { Link, Vector } from '@fragments';
import { color, spaceDt, spaceMb, screenMin } from '@helpers/styles';

const Card = styled.div`
  border: 1px solid ${color.accent[1]};
  padding: ${spaceDt(2.5)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CardDescription = styled.div`
  margin-bottom: ${spaceMb(2)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(2)};
  }
`;

const CardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${screenMin('lg')} {
    align-items: center;
  }
`;
const CardImage = styled((props) => <Vector {...props} />)`
  width: ${spaceMb(16.5)};
  height: ${spaceMb(5.5)};

  ${screenMin('lg')} {
    width: ${spaceDt(16.5)};
    height: ${spaceDt(5.5)};
  }
`;
const CardLink = styled(Link)`
  text-decoration: underline;

  ${screenMin('lg')} {
    color: ${color.bg.secondary};
  }
`;

export { Card, CardDescription, CardBottom, CardImage, CardLink };
