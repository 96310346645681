import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography } from '@helpers/styles';
import { SectionSplit, ContentBlock } from '@fragments';
import { VikingHammer } from '@illustrations';

export const StyledVikingHammer = styled(VikingHammer)`
  ${screenMax('lg')} {
    transform: scale(1.4);
  }
`;

export const SectionSplitScaling = styled(SectionSplit)`
  overflow: hidden;
  ${screenMax('lg')} {
    flex-direction: column-reverse;
  }

  ${getTypography('heading-2')};
`;

export const SectionScalingBottom = styled(ContentBlock)`
  ${screenMin('lg')} {
    padding-left: ${spaceDt(11)};
  }
`;

export const SectionScalingWrapper = styled.div`
  margin-bottom: ${spaceMb(16)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(25)};
  }
`;
