import React from 'react';
import { Container, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { VikingIsland } from '@illustrations';
import SectionCaseStudyCard from './SectionCaseStudyCard';
import { SectionSplitGrowth, SectionGrowthBottom, SectionGrowthWrapper } from './SectionGrowth.styled';
import dataSD from '../SoftwareDevelopment.data';

const { mainSection, bottomSection, caseStudiesCards } = dataSD.sectionGrowth;

const SectionGrowth = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <Strings name="software-development-growth--mobile" height={1127} top={80} mobile />
      ) : (
        <Strings name="software-development-growth" height={1430} top={-150} />
      )}
      <SectionGrowthWrapper>
        <SectionSplitGrowth
          compact
          vectorName={mainSection.icon}
          name={mainSection.name}
          title={mainSection.title}
          content={mainSection.content}
          contentAside={<VikingIsland config={{ widthMb: 350, width: 560 }} />}
        />
        <Container>
          <SectionGrowthBottom headline={bottomSection.headline} content={bottomSection.content} />
        </Container>
        <SectionCaseStudyCard slides={caseStudiesCards} />
      </SectionGrowthWrapper>
    </StringsWrapper>
  );
};

export default SectionGrowth;
