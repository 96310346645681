import React from 'react';
import { Container, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import SectionCaseStudyCard from './SectionCaseStudyCard';
import {
  StyledVikingHammer,
  SectionSplitScaling,
  SectionScalingBottom,
  SectionScalingWrapper,
} from './SectionScaling.styled';

import dataSD from '../SoftwareDevelopment.data';

const { mainSection, bottomSection, caseStudiesCards } = dataSD.sectionScaling;

const SectionScaling = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <Strings name="software-development-scaling--mobile" height={1238} top={190} mobile />
      ) : (
        <Strings name="software-development-scaling" height={2067} top={-300} />
      )}
      <SectionScalingWrapper>
        <SectionSplitScaling
          compact
          vectorName={mainSection.icon}
          name={mainSection.name}
          title={mainSection.title}
          content={mainSection.content}
          contentAside={<StyledVikingHammer config={{ widthMb: 350, width: 700 }} />}
        />
        <Container>
          <SectionScalingBottom headline={bottomSection[0].headline} content={bottomSection[0].content} />
          <SectionScalingBottom content={bottomSection[1].content} />
        </Container>
        <SectionCaseStudyCard slides={caseStudiesCards} />
      </SectionScalingWrapper>
    </StringsWrapper>
  );
};

export default SectionScaling;
