import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { TYPE_COMPONENT } from '@helpers/types';
import { useStateScreenMobile } from '@helpers/hooks';
import { CardContainer, Card } from './SectionCaseStudyCard.styled';
import { CarouselCard } from '../components';

const SectionCaseStudyCard = ({ slides }) => {
  const {
    allCaseStudiesJson: { nodes: caseStudyNodes },
  } = useStaticQuery(graphql`
    query {
      allCaseStudiesJson {
        nodes {
          id
          meta {
            slug
            codename
          }
        }
      }
    }
  `);

  const slidesToShow = slides.map((slide, index) => {
    const foundCaseStudy = caseStudyNodes.find(({ meta: { codename } }) => {
      return codename === slide.codename;
    });
    slide.link.to = `/case-studies/${foundCaseStudy === undefined ? slide.codename : foundCaseStudy.meta.slug}`;
    return <Card key={index} description={slide.description} logo={slide.logo} link={slide.link} />;
  });
  const CardsDesktop = () => <CardContainer>{slidesToShow}</CardContainer>;

  const CardsMobile = () => (
    <CarouselCard slides={slidesToShow.length <= 2 ? [...slidesToShow, ...slidesToShow] : slidesToShow} />
  );

  return useStateScreenMobile() ? <CardsMobile /> : <CardsDesktop />;
};

export default SectionCaseStudyCard;

SectionCaseStudyCard.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.exact({
      description: PropTypes.string,
      codename: PropTypes.string,
      logo: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
      link: PropTypes.exact({
        to: PropTypes.string,
        title: PropTypes.string,
        label: PropTypes.string,
      }),
    })
  ).isRequired,
};
