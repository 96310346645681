import styled from 'styled-components';
import { color, spaceDt, screenMin } from '@helpers/styles';

const SectionHeaderWrapper = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(30)};
  }
`;

const Highlight = styled.em`
  font-style: normal;
  color: ${color.bg.secondary};

  ${screenMin('lg')} {
    white-space: pre;
  }
`;

export { Highlight, SectionHeaderWrapper };
