import React from 'react';
import { PageHeader } from '@fragments';
import { SectionHeaderWrapper } from './SectionHeader.styled';
import dataSD from '../SoftwareDevelopment.data';

const {
  sectionHeader: { name, headline, content, linkArray },
} = dataSD;

const SectionHeader = () => (
  <SectionHeaderWrapper>
    <PageHeader
      id="software-development-header"
      name={name}
      headline={headline}
      content={content}
      linkArray={linkArray}
    />
  </SectionHeaderWrapper>
);

export default SectionHeader;
