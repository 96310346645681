import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'infinite-react-carousel';
import { TYPE_COMPONENT } from '@helpers/types';
import {
  SliderContainer,
  SliderContent,
  SliderControls,
  SliderArrow,
  SliderArrowButton,
  Slide,
} from './CarouselCard.styled';

export const CarouselCard = ({ className, slides }) => {
  const sliderRef = useRef();
  const slidePrev = () => sliderRef.current.slickPrev();
  const slideNext = () => sliderRef.current.slickNext();

  return (
    <SliderContainer className={className}>
      <SliderContent>
        <Slider ref={sliderRef} arrows={false}>
          {slides.map((content, index) => (
            <Slide key={index}>{content}</Slide>
          ))}
        </Slider>
      </SliderContent>
      <SliderControls>
        <SliderArrowButton onClick={slideNext}>
          <SliderArrow name="icon-arrow-round--right" />
        </SliderArrowButton>
        <SliderArrowButton onClick={slidePrev}>
          <SliderArrow name="icon-arrow-round--left" />
        </SliderArrowButton>
      </SliderControls>
    </SliderContainer>
  );
};

CarouselCard.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  slides: PropTypes.arrayOf(TYPE_COMPONENT).isRequired,
};
