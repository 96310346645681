import React from 'react';
import styled from 'styled-components';
import { getTypography } from '@helpers/styles';
import { BookingSync, Heyjobs, Growthmentor, Memorado } from '@logos';
import { Highlight } from './sections/SectionHeader.styled';

const TextBody1 = styled.p`
  ${getTypography('body-1')};
`;

const TextBody2 = styled.p`
  ${getTypography('body-1')};
  width: 105%;
`;

const dataSD = {
  sectionHeader: {
    name: 'Startups and scale-ups',
    headline: 'Software development for startups and scaleups',
    content: [
      () => (
        <TextBody1>
          {`We have developed over 50 startups over the last 15 years.
            Several of them evolved to scale-ups and leaders of their markets.
            This is how we came to grasp the importance of early tech decisions in terms of costs, speed, and scalability.`}
        </TextBody1>
      ),
      () => (
        <TextBody2>
          Whether you are just starting or scaling up - <Highlight>we can assist you during this journey.</Highlight>
        </TextBody2>
      ),
    ],
    linkArray: [
      {
        to: '/fund',
        label: 'Ragnarson Fund',
        title: 'Go to Ragnarson Fund',
      },
      {
        to: '/advisory',
        label: 'Advisory',
        title: 'Go to Advisory',
      },
    ],
  },
  sectionMVP: {
    mainSection: {
      icon: 'icon-rune--d-light',
      name: 'MVP',
      title: 'MVP workshops',
      content: `Before building an MVP, we start with a workshop.
        Together, we define the goals we want to achieve, the assumptions we want to validate,
        and think about how the users will use the early version of the product.
        The result is the scope of an MVP and the choice of the technology stack that will make it happen.`,
    },
    bottomSection: {
      headline: 'Building and supporting the MVP',
      content: `Some 2-3 developers can deliver 95% of MVPs over the period of 3 months.
        After the delivery of the initial project, we will provide maintenance
        and develop necessary updates so that you get to focus on business development and fundraising.
        We will be ready to speed up the development once it’s time to scale up your startup.`,
    },
    caseStudiesCards: [
      {
        description: `Since we launched, over 5,000 calls have been booked on the platform and we've had widespread positive critical acclaim.
          What started as an MVP build, and developed into a full-production product which is now profitable.`,
        codename: 'growthmentor',
        logo: (
          <Growthmentor
            config={{
              widthMb: 130,
              width: 140,
            }}
          />
        ),
        link: {
          title: 'Growthmentor TechEd marketplace',
          label: 'Case study',
        },
      },
      {
        description: `Ragnarson did exactly what we were looking for. In less than 4 months of development,
          they finished an MVP, three days before the deadline. It gave us a significant boost in convincing investors.`,
        codename: 'memorado',
        logo: (
          <Memorado
            config={{
              widthMb: 130,
              width: 140,
            }}
          />
        ),
        link: {
          title: 'Memorado MVP case study',
          label: 'Case study',
        },
      },
    ],
  },
  sectionGrowth: {
    mainSection: {
      icon: 'icon-rune--e-light',
      name: 'Growth phase',
      title: 'Growth and building the product',
      content: `When the MVP gains traction and you know that it's going in the right direction,
        it's time to build a product that meets your vision. We'll build the necessary features on top of the MVP
        and - if necessary - support you with a bigger team.`,
      illustration: 'map-growth',
    },
    bottomSection: {
      headline: 'Handoff to internal team',
      content: `As you grow stronger, you will  need to build an internal tech team.
        We will help you attract talents and pass our knowledge about team-building
        and technology to your team, supporting you all the way.`,
    },
    caseStudiesCards: [
      {
        description: `We received detailed developer documentation describing the architecture of our
          AWS infrastructure and tutorials on using it. The assigned consultants were very helpful
          in explaining some particular details to our developers.`,
        codename: 'bookingsync-infrastructure',
        logo: (
          <BookingSync
            config={{
              widthMb: 130,
              width: 140,
            }}
          />
        ),
        link: {
          title: 'BookingSync DevOps infrastructure case study',
          label: 'Case study',
        },
      },
      {
        description:
          'They ended up helping us to onboard our new tech lead to ensure a smooth legacy and knowledge transition.',
        codename: 'heyjobs-infrastructure',
        logo: (
          <Heyjobs
            config={{
              widthMb: 80,
              width: 90,
            }}
          />
        ),
        link: {
          title: 'HeyJobs DevOps infrastructure case study',
          label: 'Case study',
        },
      },
    ],
  },
  sectionScaling: {
    mainSection: {
      icon: 'icon-rune--e-light',
      name: 'Scaling',
      title: 'Team augmentation',
      content: `Scaling is all about speed and taking over a market before your competition.
        When it comes to tech teams, you will regularly encounter the need to implement
        a new technology or accelerate up the recruitment speed.
        With us you can be flexible and add experienced developers with required skills
        to join your team on a short-term basis.`,
      illustration: 'illustration-hammer',
    },
    bottomSection: [
      {
        headline: 'DevOps and Infrastructure',
        content: `As an application grows, a new area of scaling problems appears.
          While at first, it made complete sense to use a PaaS for hosting, now it might not be cost-effective anymore
          or not provide all the tools you need.`,
      },
      {
        content: `Our dedicated DevOps team helps companies to build tailor-made
        infrastructures that fit their applications.
          With 7+ years of experience in designing, prototyping,
          and managing AWS services, we can build a setup that allows
          tech teams to achieve optimal performance and reduce cloud fees.`,
      },
    ],
    caseStudiesCards: [
      {
        description: `Their ability to deliver the right solution, on-time every time, was brilliant.
          They excel in AWS as well as have deep knowledge of the rest of DevOps services.
          I would recommend them to anyone looking for a partner with deep experience in cloud architectures/platforms.`,
        logo: (
          <Heyjobs
            config={{
              widthMb: 80,
              width: 90,
            }}
          />
        ),
        codename: 'heyjobs-infrastructure',
        link: {
          title: 'HeyJobs DevOps infrastructure case study',
          label: 'Case study',
        },
      },
      {
        description: `Ragnarson handled the full scope of the tasks required to update and optimize our infrastructure and software delivery pipelines.
          They moved our data and applications to the cloud and performed mostly seamless migration so our team experienced no interruptions in the workflow process.`,
        logo: (
          <BookingSync
            config={{
              widthMb: 130,
              width: 140,
            }}
          />
        ),
        codename: 'bookingsync-infrastructure',
        link: {
          title: 'BookingSync AWS infrastructure case studies',
          label: 'Case study',
        },
      },
    ],
  },
};

export default dataSD;
