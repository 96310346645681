import React from 'react';
import { Container, Strings, StringsWrapper } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import SectionCaseStudyCard from './SectionCaseStudyCard';
import { VectorMVP, SectionSplitMVP, SectionMVPBottom, SectionMVPWrapper } from './SectionMVP.styled';
import dataSD from '../SoftwareDevelopment.data';

const { mainSection, bottomSection, caseStudiesCards } = dataSD.sectionMVP;

const SectionMVP = () => {
  return (
    <StringsWrapper>
      {useStateScreenMobile() ? (
        <Strings name="software-development-mvp--mobile" height={1307} top={-20} mobile />
      ) : (
        <Strings name="software-development-mvp" height={1251} top={-100} />
      )}
      <SectionMVPWrapper>
        <SectionSplitMVP
          compact
          vectorName={mainSection.icon}
          name={mainSection.name}
          title={mainSection.title}
          content={mainSection.content}
          contentAside={<VectorMVP />}
        />
        <Container>
          <SectionMVPBottom headline={bottomSection.headline} content={bottomSection.content} />
        </Container>
        <SectionCaseStudyCard slides={caseStudiesCards} />
      </SectionMVPWrapper>
    </StringsWrapper>
  );
};

export default SectionMVP;
