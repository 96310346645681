import styled from 'styled-components';
import { spaceDt, screenMin, screenMax, getTypography } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { SectionSplit, ContentBlock } from '@fragments';
import { VikingMvp } from '@illustrations';

const VectorMVP = styled(VikingMvp)`
  width: ${toVW(425, 'desktop')};
  height: ${toVW(517, 'desktop')};
  margin-left: ${spaceDt(20)};

  ${screenMax('lg')} {
    margin-left: 0;
    width: ${toVW(330, 'mobile')};
    height: ${toVW(400, 'mobile')};
    transform: scaleX(-1);
  }
`;
const SectionSplitMVP = styled(SectionSplit)`
  ${screenMax('lg')} {
    flex-direction: column-reverse;
  }

  ${getTypography('heading-2')};
`;

const SectionMVPBottom = styled(ContentBlock)`
  ${screenMin('lg')} {
    padding-left: ${spaceDt(11)};
  }
`;

const SectionMVPWrapper = styled.div`
  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(45)};
  }
`;

export { VectorMVP, SectionSplitMVP, SectionMVPBottom, SectionMVPWrapper };
