import styled from 'styled-components';
import { color, spaceDt, screenMin, screenMax } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container } from '@fragments';
import { CaseStudyCard } from '../components';

export const CardContainer = styled(Container)`
  flex-direction: row;

  ${screenMin('lg')} {
    margin-left: ${spaceDt(11)};
  }
`;

export const Card = styled(CaseStudyCard)`
  background-color: ${color.bg.light};

  ${screenMin('lg')} {
    width: ${toVW(430, 'desktop')};

    &:not(:last-child) {
      margin-right: ${spaceDt(5)};
    }
  }
  ${screenMax('lg')} {
    border: none;
  }
`;
