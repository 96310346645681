import React from 'react';
import { SEO } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import SectionHeader from './sections/SectionHeader';
import SectionMVP from './sections/SectionMVP';
import SectionGrowth from './sections/SectionGrowth';
import SectionScaling from './sections/SectionScaling';

const SoftwareDevelopment = () => (
  <>
    <SEO
      title="Custom Software Development for startups and scaleups | Ragnarson"
      description="We've been working with Startups on all stages of growth. From an MVP, through market product fit to scaling up in the capacity of the only tech team or as team argumentation."
    />
    <SectionHeader />
    <SectionMVP />
    <SectionGrowth />
    <SectionScaling />
    <ContactForm headline={<ContactFormHeader>Contact us</ContactFormHeader>} />
  </>
);

export default SoftwareDevelopment;
