import styled from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography } from '@helpers/styles';
import { SectionSplit, ContentBlock } from '@fragments';

export const SectionSplitGrowth = styled(SectionSplit)`
  ${screenMax('lg')} {
    margin: 0;
    flex-direction: column-reverse;
  }

  ${getTypography('heading-2')};
`;

export const SectionGrowthBottom = styled(ContentBlock)`
  ${screenMin('lg')} {
    padding-left: ${spaceDt(11)};
  }
`;

export const SectionGrowthWrapper = styled.div`
  margin-bottom: ${spaceMb(10)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(45)};
  }
`;
