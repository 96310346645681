import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_LINK, TYPE_COMPONENT } from '@helpers/types';
import { Card, CardDescription, CardBottom, CardImage, CardLink } from './CaseStudyCard.styled';

export const CaseStudyCard = ({ className, description, logo, link: { to, title, label } }) => {
  const image = typeof logo === 'string' ? <CardImage name={logo} /> : logo;

  return (
    <Card className={className}>
      <CardDescription>{description}</CardDescription>
      <CardBottom>
        {image}

        <CardLink to={to} title={title}>
          {label}
        </CardLink>
      </CardBottom>
    </Card>
  );
};

CaseStudyCard.propTypes = {
  /* eslint-disable react/require-default-props */
  className: PropTypes.string,
  /* eslint-enable react/require-default-props */
  description: PropTypes.string.isRequired,
  logo: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
  link: TYPE_LINK.isRequired,
};
